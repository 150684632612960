import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './index.css';

function Archive({ product }) {
  function renderArchive() {
    if (product.title.includes('[Archive S]')) {
      if (product.images.length === 0) {
        return (
          <div className="archive-item-container archive-video">
            <div className="archive-item-images">
              <video style={{ width: '100%' }} loop autoPlay muted playsInline>
                <source src={product.description} type="video/mp4" />
              </video>
            </div>
          </div>
        );
      } else {
        return (
          <div className="archive-item-container">
            <div className="archive-item-images">
              <LazyLoadImage
                threshold={64}
                className="archive-product-image-1"
                alt={product.title.split('] ')[1]}
                src={product.images[0].src}
              />
            </div>
          </div>
        );
      }
    } else if (product.title.includes('[Archive M]')) {
      if (product.images.length === 0) {
        return (
          <div className="archive-item-container-medium">
            <div className="archive-item-images">
              <video style={{ width: '100%' }} loop autoPlay muted playsInline>
                <source src={product.description} type="video/mp4" />
              </video>
            </div>
          </div>
        );
      } else {
        return (
          <div className="archive-item-container-medium">
            <div className="archive-item-images">
              <LazyLoadImage threshold={64} className="product-image-1" alt={product.title.split('] ')[1]} src={product.images[0].src} />
            </div>
          </div>
        );
      }
    } else {
      return <></>;
    }
  }

  return <>{renderArchive()}</>;
}

export default Archive;
